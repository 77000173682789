<template>
  <component
    :is="component"
    :content="zone.content"
    :layout="zoneLayout"
    :style="styles"
    :skeleton="zone.layout.skeleton"
  ></component>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import isPlainObject from "lodash/isPlainObject";

export default {
  name: "PlayerZone",
  components: {
    FacebookReviewElement: () =>
      import("@/components/facebookreviews/FacebookReviewElement"),
    GoogleReviewElement: () =>
      import("@/components/googlereviews/GoogleReviewElement"),
    TripadvisorReviewElement: () =>
      import("@/components/tripadvisorreviews/TripadvisorReviewElement"),
    PromotionElement: () => import("@/components/promotion/PromotionElement"),
    MyBusinessElement: () =>
      import("@/components/mybusiness/MyBusinessElement"),
    SocialSlideElement: () =>
      import("@/components/socialslide/SocialSlideElement"),
    LiveNews: () => import("@/components/livenews/LiveNews"),
    QueueManager: () => import("@/components/queuemanager/QueueManager"),
    WebPages: () => import("@/components/webpages/WebPages"),
    GoogleSlides: () => import("@/components/googleslides/GoogleSlides"),
    PlayerBackground: () =>
      import("@/components/playerbackground/PlayerBackground"),
  },
  props: {
    zone: Object,
  },
  data() {
    return {
      //
    };
  },
  computed: {
    zoneLayout: function() {
      var layout = cloneDeep(this.zone.layout);
      Object.keys(layout).forEach((l) => {
        if (isPlainObject(layout[l])) {
          layout[l].zoneWidth = this.zone.width;
          layout[l].zoneHeight = this.zone.height;
        }
      });

      return layout;
    },
    component: function() {
      if (!this.zone.appType) {
        return null;
      }

      switch (this.zone.appType.toLowerCase()) {
        case "facebookreviews":
          return "FacebookReviewElement";
        case "googlereviews":
          return "GoogleReviewElement";
        case "tripadvisor":
          return "TripadvisorReviewElement";
        case "promotion":
          return "PromotionElement";
        case "mybusiness":
          return "MyBusinessElement";
        case "socialslide":
          return "SocialSlideElement";
        case "livenews":
          return "LiveNews";
        case "queuemanager":
          return "QueueManager";
        case "webpages":
          return "WebPages";
        case "googleslides":
          return "GoogleSlides";
        case "playerbackground":
          return "PlayerBackground";
      }

      return null;
    },
    styles: function() {
      return {
        position: "absolute",
        top: this.zone.y * 100 + "%",
        left: this.zone.x * 100 + "%",
        width: this.zone.width * 100 + "%",
        height: this.zone.height * 100 + "%",
      };
    },
  },
  methods: {
    //
  },
};
</script>

<style></style>
